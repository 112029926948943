<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card :title="$t('Add_Product.Attributes_and_Values')">
          <b-row>
            <b-col cols="12">
              <div>
                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in product.attributes"
                  :key="'attr' + index"
                >
                  <!-- Attrbuites -->
                  <b-col
                    md="2"
                    class="d-flex justify-content-center align-items-center"
                  >
                    <!-- <validation-provider #default="{ errors }" name="attribute" rules="required">
                                                        <b-form-group label="Attribute" label-for="attribute" :state="errors.length > 0 ? false:null">
                                                            <v-select disabled :id="'attributes' + index" v-model="attrSelect[index]" :clearable="false" @input="getValues(index,attrSelect[index].value)" :state="attrSelect[index] === null ? false : true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="attr2Options"
                                                                :selectable="option => ! option.value.includes('select_value')" label="text" />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </b-form-group>
                                                    </validation-provider> -->
                    <p class="text-primary mb-0 font-weight-bold h4">
                      {{ attrSelect[index].text }}
                    </p>
                  </b-col>
                  <b-col v-if="attrSelect[index].is_variant" md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="القيم"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Add_Product.Values')"
                        label-for="values"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          multiple
                          :id="'values' + index"
                          v-model="valuesSelect[index]"
                          :clearable="false"
                          :state="valuesSelect[index] === null ? false : true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="valuesOptions[index]"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col v-else md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="القيم"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Add_Product.Values')"
                        label-for="values"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          :id="'values' + index"
                          v-model="valuesSelect[index]"
                          :clearable="false"
                          :state="valuesSelect[index] === null ? false : true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="valuesOptions[index]"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Remove Button -->
                  <!-- <b-col lg="2" md="3" class="mb-50">
                                                                                                                                        <b-button v-if="index > 0" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="taxonomyremoveItem(index)">
                                                                                                                                            <feather-icon icon="XIcon" class="mr-25" />
                                                                                                                                            <span>Remove</span>
                                                                                                                                        </b-button>
                                                                                                                                    </b-col> -->
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <!-- add new button -->
            <!-- <b-col sm="2">
                                                                                                                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" block @click="taxonomyrepeateAgain">
                                                                                                                                <feather-icon icon="PlusIcon" class="mr-25" />
                                                                                                                                <span> {{$t('Add_Product.Add_New')}}   </span>
                                                                                                                            </b-button>
                                                                                                                        </b-col> -->
          </b-row>
          <b-button class="mt-2" @click="generate" block variant="success">
            {{ $t("Add_Product.Generate") }}
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" v-if="varints.length > 0">
        <b-card :title="$t('Add_Product.Variants')" ref="varintsHeight">
          <b-row>
            <b-col cols="12">
              <div>
                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in varints"
                  :key="'variant' + index"
                >
                  <b-col cols="12">
                    <app-collapse
                      id="faq-payment-qna"
                      type="margin"
                      class="mt-0 mb-0"
                    >
                      <app-collapse-item
                        :key="'variantIndex' + index"
                        :title="item.vname"
                      >
                        <b-row>
                          <b-col cols="12" class="">
                            <b-row>
                              <!-- price button -->
                              <b-col
                                md="2"
                                class="d-flex justify-content-center align-items-center"
                              >
                                <b-button
                                  variant="warning"
                                  @click="setPrice(item, index)"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  v-b-modal.modal-prevent-closing
                                  >{{ $t("Add_Product.Add_Price") }}</b-button
                                >
                              </b-col>
                              <!-- varint quantity -->
                              <b-col
                                md="2"
                                class="d-flex justify-content-center align-items-center"
                              >
                                <b-form-group
                                  :label="$t('Add_Product.Quantity')"
                                  label-for="quantity"
                                >
                                  <validation-provider
                                    #default="{ errors }"
                                    name="الكمية"
                                    rules="required|regex:^[0]*[1-9][0-9]*$"
                                  >
                                    <b-form-input
                                      :id="'quantity' + index"
                                      :disabled="
                                        productData.is_new == 'used'
                                          ? true
                                          : false
                                      "
                                      min="0"
                                      type="number"
                                      v-model="
                                        productData.is_new == 'used'
                                          ? (item.vquantity = 1)
                                          : item.vquantity
                                      "
                                      :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col
                                md="4"
                                v-show="
                                  item.salePrice > 0 && item.salePrice != null
                                "
                              >
                                <p class="text-dark font-weight-bold">
                                  {{ $t("Add_Product.Original_Price") }} :
                                  {{ item.voriginal_price }} EGP
                                </p>
                                <p
                                  class=" font-weight-bold"
                                  v-show="
                                    item.offerBDel.amount != 0 &&
                                      item.offerBDel.amount != '' &&
                                      item.offerBDel.amount != null
                                  "
                                >
                                  الخصم:
                                  <b-badge
                                    varaint="primary"
                                    v-if="item.offerBDel.type == 'fixed_price'"
                                    >{{ item.offerBDel.amount }} EGP</b-badge
                                  >
                                  <b-badge variant="primary" v-else
                                    >{{ item.offerBDel.amount }}%</b-badge
                                  >
                                </p>
                                <p class="text-dark font-weight-bold">
                                  {{ $t("Product_Index.Platform_Price") }}
                                  : {{ item.salePrice }} EGP
                                </p>
                                <p class="text-dark font-weight-bold">
                                  {{ $t("Product_Index.Platform_Profit") }}
                                  : {{ item.profit }} EGP
                                </p>
                              </b-col>

                              <b-col cols="12">
                                <hr />
                              </b-col>
                              <b-col cols="12">
                                <b-row>
                                  <b-col>
                                    <b-row>
                                      <b-col cols="12">
                                        <div>
                                          <b-form
                                            ref="vImagesform"
                                            :style="{ height: trHeight }"
                                            class="repeater-form"
                                            @submit.prevent="
                                              vImagesRepeateAgain(item)
                                            "
                                          >
                                            <!-- Row Loop -->
                                            <b-row
                                              v-for="(im, ind) in item.vimages2"
                                              :id="im + ind"
                                              :key="im + ind"
                                              ref="row"
                                            >
                                              <!-- Image Reapeted -->
                                              <b-col
                                                md="4"
                                                class="mt-auto mb-auto"
                                              >
                                                <b-form-group
                                                  :label="
                                                    $t('Add_Product.Image')
                                                  "
                                                  label-for="image"
                                                >
                                                  <b-form-file
                                                    @change="
                                                      uploadImage3(
                                                        $event,
                                                        item,
                                                        ind
                                                      )
                                                    "
                                                    :id="
                                                      'image' +
                                                        ind +
                                                        index +
                                                        ind
                                                    "
                                                    v-model="
                                                      item.vimages3[ind].vname
                                                    "
                                                    accept="image/*"
                                                  />
                                                </b-form-group>
                                              </b-col>
                                              <b-col md="2">
                                                <b-media class="mb-1 mr-auto">
                                                  <b-img
                                                    v-if="
                                                      item.vimages2[ind]
                                                        .vname != null &&
                                                        item.vimages2[ind]
                                                          .vname != ''
                                                    "
                                                    class="img-thumbnail"
                                                    ref="previewEl"
                                                    :src="
                                                      item.vimages2[ind].vname
                                                    "
                                                    height="100px"
                                                  />
                                                </b-media>
                                              </b-col>
                                              <!-- Remove Button -->
                                              <b-col
                                                lg="2"
                                                md="3"
                                                class="mt-auto mb-auto"
                                              >
                                                <b-button
                                                  v-ripple.400="
                                                    'rgba(234, 84, 85, 0.15)'
                                                  "
                                                  v-if="
                                                    item.vimages2.length > 1
                                                  "
                                                  variant="outline-danger"
                                                  class="mt-0 mt-md-2"
                                                  @click="
                                                    vImagesRemoveItem(ind, item)
                                                  "
                                                >
                                                  <feather-icon
                                                    icon="XIcon"
                                                    class="mr-25"
                                                  />
                                                  <span>{{
                                                    $t("Add_Product.Remove")
                                                  }}</span>
                                                </b-button>
                                              </b-col>
                                              <b-col cols="12">
                                                <hr />
                                              </b-col>
                                            </b-row>
                                          </b-form>
                                        </div>
                                      </b-col>
                                      <!-- add new button -->
                                      <b-col cols="12">
                                        <b-button
                                          v-ripple.400="
                                            'rgba(255, 255, 255, 0.15)'
                                          "
                                          style="width:40%"
                                          variant="outline-primary"
                                          block
                                          @click="vImagesRepeateAgain(item)"
                                        >
                                          <feather-icon
                                            icon="PlusIcon"
                                            class="mr-25"
                                          />
                                          <span>{{
                                            $t("Add_Product.Add_New_Image")
                                          }}</span>
                                        </b-button>
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <!-- Remove Button -->
                          <!-- <b-col cols="2" class="mb-50">
                                                                                                                                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="flat-danger" class=" m-2 border-0" @click="pricesremoveItem(index)">
                                                                                                                                    <feather-icon icon="XIcon" class="mr-25" />
                                                                                                                                    <span>Remove</span>
                                                                                                                                </b-button>
                                                                                                                            </b-col> -->
                          <b-col cols="12">
                            <hr />
                          </b-col>
                        </b-row>
                      </app-collapse-item>
                    </app-collapse>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <!-- add Price Model -->
          <b-modal
            id="modal-prevent-closing"
            cancel-variant="outline-secondary"
            @ok="handleOk"
            ok-title="أضف"
            cancel-title="غلق"
            centered
            :title="$t('Add_Product.Add_Price')"
          >
            <validation-observer ref="addPrice">
              <b-form @submit.stop.prevent="addPriceRate">
                <b-row>
                  <!-- varint price -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('Add_Product.Price')"
                      label-for="price"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="السعر"
                        rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                      >
                        <b-form-input
                          v-model="variantItem.voriginal_price"
                          min="0"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- varint Amount -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('Add_Product.Amount')"
                      label-for="amount"
                    >
                      <b-form-input
                        min="1"
                        type="number"
                        v-model="variantItem.offerBDel.amount"
                        placeholder="enter amount"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col v-if="variantItem.offerBDel.amount > 0" md="12">
                    <b-row>
                      <b-col>
                        <app-collapse
                          id="faq-payment-qna"
                          type="margin"
                          class="mt-0 mb-0"
                        >
                          <app-collapse-item
                            :key="variantIndex"
                            title="Click To Customize Offer"
                          >
                            <b-row>
                              <b-col md="6">
                                <validation-provider
                                  #default="{ errors }"
                                  name="نوع العرض"
                                  rules="required"
                                >
                                  <b-form-group
                                    :label="$t('Add_Product.Offer_Type')"
                                    label-for="Offer Type"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <v-select
                                      id="Offer-Type"
                                      v-model="offerTypeSelect"
                                      :state="
                                        offerTypeSelect === null ? false : true
                                      "
                                      :dir="
                                        $store.state.appConfig.isRTL
                                          ? 'rtl'
                                          : 'ltr'
                                      "
                                      :options="offerTypeOptions"
                                      :selectable="
                                        (option) =>
                                          !option.value.includes('select_value')
                                      "
                                      label="text"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                              <b-col md="6">
                                <b-form-group
                                  :label="$t('Add_Product.User_Numbers')"
                                  label-for="User-Numbers"
                                >
                                  <validation-provider
                                    #default="{ errors }"
                                    name="عدد المستخدمين"
                                    rules="required|regex:^[0]*[1-9][0-9]*$"
                                  >
                                    <b-form-input
                                      min="1"
                                      id="User-Numbers"
                                      type="number"
                                      v-model="
                                        variantItem.offerBDel.usernumbers
                                      "
                                      :state="errors.length > 0 ? false : null"
                                      placeholder="عدد المستخدمين"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="6">
                                <validation-provider
                                  #default="{ errors }"
                                  name="تاريخ البدء"
                                  rules="required"
                                >
                                  <b-form-group
                                    :label="$t('Add_Product.Start_Date')"
                                    label-for="start date"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <flat-pickr
                                      v-model="variantItem.offerBDel.startdate"
                                      class="form-control"
                                      :config="{
                                        enableTime: true,
                                        dateFormat: 'Y-m-d',
                                        minDate: 'today',
                                      }"
                                    />
                                    <b-form-invalid-feedback
                                      :state="errors.length > 0 ? false : null"
                                    >
                                      {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                              <b-col md="6">
                                <validation-provider
                                  #default="{ errors }"
                                  name="تاريخ الانتهاء"
                                  rules="required"
                                >
                                  <b-form-group
                                    :label="$t('Add_Product.End_Date')"
                                    label-for="end date"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <flat-pickr
                                      v-model="variantItem.offerBDel.enddate"
                                      :disabled="
                                        variantItem.offerBDel.startdate ==
                                          null ||
                                          variantItem.offerBDel.startdate == ''
                                      "
                                      class="form-control"
                                      :config="{
                                        enableTime: true,
                                        dateFormat: 'Y-m-d',
                                        minDate:
                                          variantItem.offerBDel.startdate,
                                      }"
                                    />
                                    <b-form-invalid-feedback
                                      :state="errors.length > 0 ? false : null"
                                    >
                                      {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                            </b-row>
                          </app-collapse-item>
                        </app-collapse>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
            <!-- error handelr -->
            <b-alert
              v-height-fade.appear
              fade
              :show="showDismissibleAlert2"
              @dismissed="showDismissibleAlert2 = false"
              variant="danger"
            >
              <h4 class="alert-heading">
                Alert
              </h4>
              <div class="alert-body">
                <ul v-for="(values , index) in errors_back2" :key="index">
                  <li v-for="(value , valIndex) in values" :key="valIndex">
                    {{ value }}
                  </li>
                </ul>
              </div>
            </b-alert>
          </b-modal>
        </b-card>
      </b-col>
    </b-row>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , valIndex) in values" :key="valIndex">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import { required } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
  },
 
  mixins: [heightTransition],
  data() {
    return {
      required,
      errors_back2: [],
      showDismissibleAlert2: false,
      errors_back: [],
      showDismissibleAlert: false,
      vendorCommissionRate: null,
      itemData: null,
      id: 0,
      itemId: null,
      vendorId: null,
      offerTypeSelect: null,
      offerTypeOptions: [
        {
          text: "Fixed Price",
          value: "fixed_price",
        },
        {
          text: "Percentage",
          value: "percentage",
        },
      ],
      variantItem: {
        offerHide: false,
        offerBDel: {
          type: null,
          amount: 0,
          startdate: null,
          enddate: null,
          usernumbers: null,
        },
        voriginal_price: "",
      },
      variantIndex: null,
      attrSelect: [],
      attrOptions: [],
      attr2Options: [],
      vTransSelect: [],
      localeOptions: [
        {
          value: "en",
          text: "English",
        },
        {
          value: "ar",
          text: "Arabic",
        },
      ],
      tranLocaleSelect: [
        {
          value: "en",
          text: "English",
          disa: true,
        },
        {
          value: "ar",
          text: "Arabic",
          disa: true,
        },
      ],
      vConditionSelect: [],
      conditionOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      valuesSelect: [],
      valuesOptions: [
        [
          {
            value: "select_value",
            text: "Select Value",
          },
        ],
      ],
      valuesID: [],
      combTextArr: [],
      combValuesArr: [],
      varints: [],
      files: null,
      warrantyOptions: [
        {
          text: "Seller",
          value: "seller",
        },
        {
          text: "Agency",
          value: "agency",
        },
      ],
      warrantyDurationSelect: [],
      warrantyDurationOptions: [
        {
          text: "7 يوم",
          value: "7",
        },
        {
          text: "14 يوم",
          value: "14",
        },
        {
          text: "30 يوم",
          value: "30",
        },
      ],
      warrantySelect: [],
      product: {
        is_new: "new",
        attributes: [],
        variants: [],
      },
      oldVariantTitle: [],
      oldVariantId: [],
      translations: [],
    };
  },
  watch: {
    attrSelect: function() {
      this.getOptions();
    },
  },
  methods: {
    setPrice(item, index) {
      this.variantItem = item;
      this.variantIndex = index;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.addPriceRate();
    },
    async addPriceRate() {
      this.showDismissibleAlert2 = false;
      const isValid = await this.$refs[`addPrice`][0].validate();
      if (isValid) {
        axios
          .post("products/sale-price/get", {
            original_price: this.variantItem.voriginal_price,
            product_type: "new",
            item_id: this.itemId,
            offer_type:
              this.offerTypeSelect == null ? null : this.offerTypeSelect.value,
            offer_amount:
              this.variantItem.offerBDel.amount == 0
                ? null
                : this.variantItem.offerBDel.amount,
            vendor_id: this.vendorId,
          })
          .then((result) => {
            this.varints[this.variantIndex].offerBDel.amount =
              this.variantItem.offerBDel.amount == 0
                ? null
                : this.variantItem.offerBDel.amount;
            this.varints[this.variantIndex].offerBDel.type =
              this.offerTypeSelect == null ? null : this.offerTypeSelect.value;
            this.varints[
              this.variantIndex
            ].voriginal_price = this.variantItem.voriginal_price;
            this.varints[this.variantIndex].offerBDel.startdate =
              this.variantItem.offerBDel.startdate == null
                ? null
                : this.variantItem.offerBDel.startdate;
            this.varints[this.variantIndex].offerBDel.enddate =
              this.variantItem.offerBDel.enddate == null
                ? null
                : this.variantItem.offerBDel.enddate;
            this.varints[this.variantIndex].offerBDel.usernumbers =
              this.variantItem.offerBDel.usernumbers == null
                ? null
                : this.variantItem.offerBDel.usernumbers;
            this.varints[this.variantIndex].profit = result.data.profit;
            this.varints[this.variantIndex].salePrice = result.data.sale_price;
            this.$nextTick(() => {
              this.$bvModal.hide("modal-prevent-closing");
            });
          })
          .catch((err) => {
            this.errors_back2.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back2.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back2 = err.response.data.data;
              }
              this.showDismissibleAlert2 = true;
            } else {
              this.errors_back2 = [];
              this.errors_back2.push({
                error: "internal server error",
              });
              this.showDismissibleAlert2 = true;
            }
          });
      } else {
      }
    },
    // set attrbuites
    getOptions() {
      this.attr2Options = this.attrOptions.filter(
        (item) => !this.attrSelect.includes(item)
      );
    },
    // generate variants
    generate() {
      for (let val in this.valuesSelect) {
        const vale = this.valuesSelect[val];
        this.product.attributes[val].values = [];
        if (this.product.is_new == "used") {
          this.product.attributes[val].values.push(vale.value);
        } else {
          if (Array.isArray(vale)) {
            for (let valID in vale) {
              this.product.attributes[val].values.push(vale[valID].value);
            }
          } else {
            this.product.attributes[val].values.push(vale.value);
          }
        }
      }

      for (let att in this.product.attributes) {
        const attrbu = this.product.attributes[att];
        attrbu.id = this.attrSelect[att].value;
      }
      var mainValues = [];
      for (let val in this.valuesSelect) {
        const vale = this.valuesSelect[val];
        if (Array.isArray(vale)) {
          mainValues.push(vale);
        }
      }
      for (let main in mainValues) {
        const mainVal = mainValues[main];
        this.combTextArr[main].length = 0;
        if (this.product.is_new == "used") {
          this.combTextArr[main].push(mainVal.text);
        } else {
          if (Array.isArray(mainVal)) {
            for (let valID in mainVal) {
              this.combTextArr[main].push(mainVal[valID].text);
            }
          }
        }
      }
      for (let main in mainValues) {
        const mainVal = mainValues[main];
        this.combValuesArr[main].length = 0;
        if (this.product.is_new == "used") {
          this.combValuesArr[main].push(mainVal.value);
        } else {
          if (Array.isArray(mainVal)) {
            for (let valID in mainVal) {
              this.combValuesArr[main].push(mainVal[valID].value);
            }
          }
        }
      }
      // for (let val in this.valuesSelect) {
      //     const vale = this.valuesSelect[val]
      //     this.combValuesArr[val] = []
      //     if (this.product.is_new == 'used') {
      //         this.combValuesArr[val].push(vale.value)
      //     } else {
      //         if (Array.isArray(vale)) {
      //             for (let valID in vale) {
      //                 this.combValuesArr[val].push(vale[valID].value)
      //             }
      //         }
      //     }
      // }
      this.getCombn(this.combTextArr);
      this.getCombn2(this.combValuesArr);
      for (let tran in this.varints) {
        // if (this.product.original_price != '' && this.product.original_price != null) {
        //     this.varints[tran].voriginal_price = this.product.original_price
        //     let price = parseFloat(this.varints[tran].voriginal_price)
        //     let profit = (price * this.vendorCommissionRate) / 100
        //     this.varints[tran].salePrice = price + profit
        //     this.varints[tran].amountPriceDis = false
        // } else {
        //     this.varints[tran].voriginal_price = this.product.original_price
        //     this.varints[tran].amountPriceDis = true
        // }
        this.varints[tran].vis_new = this.product.is_new;
      }
      this.subActive = false;
    },
    // make combination to get names of variants
    getCombn(arr) {
      if (arr.length == 1) {
        var ans = [];
        for (let ind in arr[0]) {
          ans.push(arr[0][ind]);
        }
        this.warrantySelect.length = 0;
        this.warrantyDurationSelect.length = 0;
        this.varints.length = 0;
        for (let inde in ans) {
          this.warrantySelect.push();
          this.warrantyDurationSelect.push();
          this.varints.push({
            vname: ans[inde],
            vquantity: "1",
            vis_new: "new",
            warranty: {
              type: null,
              duration: null,
              end_date: null,
              agency_file: null,
            },
            voriginal_price: "",
            salePrice: "",
            amountPriceDis: true,
            report_serial_number: "",
            vtranslations: [
              {
                vlocale1: {
                  text: "English",
                  value: "en",
                  disa: true,
                },
                vlocale: "",
                vdescription: "",
                vshort_description: "",
              },
              {
                vlocale1: {
                  text: "Arabic",
                  value: "ar",
                  disa: true,
                },
                vlocale: "",
                vdescription: "",
                vshort_description: "",
              },
            ],
            offerHide: false,
            offerBDel: {
              type: "fixed_price",
              amount: "",
              startdate: "",
              enddate: "",
              usernumbers: "",
            },
            offer: {
              type: "fixed_price",
              amount: "",
              startdate: "",
              enddate: "",
              usernumbers: "",
            },
            vimages: [
              {
                vname: "",
              },
            ],
            vimages2: [
              {
                vname: "",
              },
            ],
            vimages3: [
              {
                vname: [],
              },
            ],
          });
        }
        return ans;
      } else {
        var ans = [];
        // recur with the rest of the array.
        var otherCases = this.getCombn(arr.slice(1));
        for (var i = 0; i < otherCases.length; i++) {
          for (var j = 0; j < arr[0].length; j++) {
            ans.push(arr[0][j] + " - " + otherCases[i]);
          }
        }
        this.warrantySelect.length = 0;
        this.varints.length = 0;
        this.warrantyDurationSelect.length = 0;
        for (let ind in ans) {
          this.warrantySelect.push();
          this.warrantyDurationSelect.push();
          this.varints.push({
            vname: ans[ind],
            vquantity: "1",
            voriginal_price: "",
            salePrice: "",
            vis_new: "new",
            warranty: {
              type: null,
              duration: null,
              end_date: null,
              agency_file: null,
            },
            amountPriceDis: true,
            report_serial_number: "",
            vtranslations: [
              {
                vlocale1: {
                  text: "English",
                  value: "en",
                  disa: true,
                },
                vlocale: "",
                vdescription: "",
                vshort_description: "",
              },
              {
                vlocale1: {
                  text: "Arabic",
                  value: "ar",
                  disa: true,
                },
                vlocale: "",
                vdescription: "",
                vshort_description: "",
              },
            ],
            offerHide: false,
            offerBDel: {
              type: "fixed_price",
              amount: "",
              startdate: "",
              enddate: "",
              usernumbers: "",
            },
            offer: {
              type: "fixed_price",
              amount: "",
              startdate: "",
              enddate: "",
              usernumbers: "",
            },
            vimages: [
              {
                vname: "",
              },
            ],
            vimages2: [
              {
                vname: "",
              },
            ],
            vimages3: [
              {
                vname: [],
              },
            ],
          });
        }
        return ans;
      }
    },
    // make combinations to get values of variants
    getCombn2(arr) {
      if (arr.length == 1) {
        var ans = [];
        for (let ind in arr[0]) {
          ans.push(arr[0][ind]);
        }
        for (let inde in ans) {
          this.varints[inde].values = [ans[inde]];
        }
        return ans;
      } else {
        var ans = [];
        // recur with the rest of the array.
        var otherCases = this.getCombn2(arr.slice(1));
        for (var i = 0; i < otherCases.length; i++) {
          for (var j = 0; j < arr[0].length; j++) {
            ans.push(arr[0][j] + "-" + otherCases[i]);
          }
        }
        for (let ind in ans) {
          this.varints[ind].values = ans[ind];
          for (let valu in this.valuesSelect) {
            if (
              !Array.isArray(this.valuesSelect[valu]) &&
              this.valuesSelect[valu].is_variant == false
            ) {
              this.varints[ind].values += "-" + this.valuesSelect[valu].value;
            }
          }
        }
        return ans;
      }
    },
    // get values
    getValues(index, id) {
      axios
        .get("products/get_values/" + id)
        .then((result) => {
          this.valuesOptions[index].length = 0;
          this.valuesSelect[index] = null;
          let data = result.data.data;
          for (let ind in data) {
            if (data[ind].translation != null) {
              this.valuesOptions[index].push({
                value: data[ind].id.toString(),
                text: data[ind].translation.name,
              });
            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    // show offer in variants
    showOffer(index) {
      const oP = parseFloat(this.varints[index].voriginal_price);
      const sP = parseFloat(this.varints[index].salePrice);
      if (
        this.varints[index].salePrice > 0 &&
        this.varints[index].salePrice != "" &&
        this.varints[index].salePrice != null &&
        oP >= sP
      ) {
        this.varints[index].offerHide = true;
        this.varints[index].offerBDel.amount =
          this.varints[index].voriginal_price - this.varints[index].salePrice;
        this.varints[index].offerBDel.type = "fixed_price";
      } else {
        this.varints[index].offerHide = false;
        (this.varints[index].offerBDel.amount = ""),
          (this.varints[index].offerBDel.type = ""),
          (this.varints[index].offerBDel.startdate = "");
        this.varints[index].offerBDel.enddate = "";
        this.varints[index].offerBDel.usernumbers = "";
      }
    },
    // return vendor commission rate
    fetchRateData() {
      // axios.get('vendors/' + this.vendorSelect.value + "/show").then((result) => {
      //     const data = result.data.data
      //     this.vendorCommissionRate = data.commission.rate
      // }).catch((err) => {
      //     this.errors_back.length = 0
      //     if (err.response.data.data != null) {
      //         if (this.isString(err.response.data.data)) {
      //             this.errors_back.push({
      //                 error: err.response.data.data
      //             })
      //         } else {
      //             this.errors_back = err.response.data.data
      //         }
      //         this.showDismissibleAlert = true
      //     } else {
      //         this.errors_back = []
      //         this.errors_back.push({
      //             error: 'internal server error'
      //         })
      //         this.showDismissibleAlert = true
      //     }
      // })
    },
    // when change amount of offer
    changeAmount(index) {
      const oP = parseFloat(this.varints[index].voriginal_price);
      const aP = parseFloat(this.varints[index].offerBDel.amount);
      if (
        this.varints[index].offerBDel.amount > 0 &&
        this.varints[index].offerBDel.amount != "" &&
        this.varints[index].offerBDel.amount != null &&
        oP > aP
      ) {
        this.varints[index].offerHide = true;
        let price =
          parseFloat(this.varints[index].voriginal_price) -
          parseFloat(this.varints[index].offerBDel.amount);
        let profit = (price * this.vendorCommissionRate) / 100;
        this.varints[index].salePrice = price + profit;
        this.varints[index].offerBDel.type = "fixed_price";
      } else {
        (this.varints[index].offerBDel.amount = ""),
          (this.varints[index].offerBDel.type = "");
        this.varints[index].offerBDel.startdate = "";
        this.varints[index].offerBDel.enddate = "";
        this.varints[index].offerBDel.usernumbers = "";
        this.varints[index].offerHide = false;
        let price = parseFloat(this.varints[index].voriginal_price);
        let profit = (price * this.vendorCommissionRate) / 100;
        this.varints[index].salePrice = price + profit;
      }
    },
    // when change original price of vaiants
    changeOP(index) {
      if (
        this.varints[index].voriginal_price > 0 &&
        this.varints[index].voriginal_price != "" &&
        this.varints[index].voriginal_price != null
      ) {
        this.varints[index].amountPriceDis = false;
        let price = parseFloat(this.varints[index].voriginal_price);
        let profit = (price * this.vendorCommissionRate) / 100;
        this.varints[index].salePrice = price + profit;
        this.varints[index].offerBDel.type = "fixed_price";
      } else {
        this.varints[index].amountPriceDis = true;
        (this.varints[index].amount = ""),
          (this.varints[index].offerBDel.amount = ""),
          (this.varints[index].offerBDel.type = ""),
          (this.varints[index].offerBDel.startdate = "");
        this.varints[index].offerBDel.enddate = "";
        this.varints[index].offerBDel.usernumbers = "";
        this.varints[index].salePrice = "";
        this.varints[index].amountPriceDis = false;
        let price = parseFloat(this.varints[index].voriginal_price);
        let profit = (price * this.vendorCommissionRate) / 100;
        this.varints[index].salePrice = price + profit;
      }
    },
    taxonomyrepeateAgain() {
      //    this.refheightForm = this.$refs.Taxonomiesform
      this.product.attributes.push({
        id: "",
        values: [],
      });
      this.combTextArr.push([]);
      this.attrSelect.push({});
      this.valuesOptions.push([
        {
          value: "select_value",
          text: "Select Value",
        },
      ]);
      this.valuesSelect.push();
      this.valuesID.push();
    },
    taxonomyremoveItem(index) {
      if (this.product.attributes.length <= 1) {
        this.$bvModal.msgBoxConfirm("You need minmum one tab to continue.", {
          title: "Alert",
          size: "sm",
          okVariant: "danger",
          okTitle: "Accept",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        });
      } else {
        this.valuesOptions[index].length = 0;
        this.combTextArr.splice(index, 1);
        this.product.attributes.splice(index, 1);
        this.attrSelect.splice(index, 1);
        this.valuesSelect.splice(index, 1);
        this.valuesID.splice(index, 1);
      }
    },
    pricesremoveItem(index) {
      if (this.varints.length <= 1) {
        this.$bvModal.msgBoxConfirm("You need minmum one tab to continue.", {
          title: "Alert",
          size: "sm",
          okVariant: "danger",
          okTitle: "Accept",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        });
      } else {
        this.varints.splice(index, 1);
        this.vConditionSelect.splice(index, 1);
      }
    },
    addVariant(index, indString) {
      this.$refs.element[index].validate().then((success) => {
        if (success) {
          this.varints[index].warranty.type = this.warrantySelect[index].value;
          if (this.varints[index].warranty.type == "seller") {
            this.varints[index].warranty.duration = this.warrantyDurationSelect[
              index
            ].value;
          }

          for (let ind in this.varints[index].vtranslations) {
            this.varints[index].vtranslations[ind].locale = this.varints[
              index
            ].vtranslations[ind].locale1.value;
          }
          const vria = {
            original_price: this.varints[index].voriginal_price,
            quantity: this.varints[index].vquantity,
            product_id: this.id,
            images: [],
            values: this.varints[index].values
              .split("-")
              .map((item) => item.trim()),
            translations: this.varints[index].vtranslations,
            offer: {
              type: this.varints[index].offerBDel.type,
              startdate: this.varints[index].offerBDel.startdate,
              enddate: this.varints[index].offerBDel.enddate,
              usernumbers: this.varints[index].offerBDel.usernumbers,
              amount: this.varints[index].offerBDel.amount,
            },
          };
          if (vria.offer.amount == "") {
            delete vria.offer;
          }
          for (let inde in this.varints[index].vimages2) {
            vria.images.push({
              name: this.varints[index].vimages2[inde].vname,
            });
            if (vria.images[inde].name.includes(",")) {
              vria.images[inde].name = vria.images[inde].name
                .split(",")
                .map((item) => item.trim());
              vria.images[inde].name = vria.images[inde].name[1];
            }
          }
          axios
            .post("products/" + this.id + "/variant-store", vria)
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    vTransRepeateAgain(item) {
      item.vtranslations.push({
        locale1: {
          text: "",
          value: "",
        },
        locale: "",
        description: "",
        short_description: "",
      });
    },
    vTransRemoveItem(index, item) {
      if (item.vtranslations.length <= 1) {
        this.$bvModal.msgBoxConfirm("You need minmum one tab to continue.", {
          title: "Alert",
          size: "sm",
          okVariant: "danger",
          okTitle: "Accept",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        });
      } else {
        item.vtranslations.splice(index, 1);
      }
    },
    vImagesRepeateAgain(item) {
      //  this.refheightForm = this.$refs.pricesForm
      item.vimages.push({
        vname: "",
      });
      item.vimages2.push({
        vname: "",
      });
      item.vimages3.push({
        vname: [],
      });
    },
    vImagesRemoveItem(index, item) {
      if (item.vimages2.length <= 1) {
        this.$bvModal.msgBoxConfirm("You need minmum one tab to continue.", {
          title: "Alert",
          size: "sm",
          okVariant: "danger",
          okTitle: "Accept",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        });
      } else {
        item.vimages2.splice(index, 1);
        item.vimages.splice(index, 1);
        item.vimages3.splice(index, 1);
      }
    },
    // upload function vimages of item
    uploadImage3(event, item, index) {
      let files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        return;
      } else {
        this.createImage3(files[0], index, item);
      }
    },
    createImage3(file, ind, item) {
      let reader = new FileReader();
      reader.onload = (e) => {
        item.vimages2[ind].vname = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    fetchData() {
      axios
        .get("products/" + this.id)
        .then((result) => {
          this.itemData = result.data.data;
          // this.vendorCommissionRate = this.itemData.vendor.commission.rate
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    getVariantTitles() {
      axios
        .get("variants/products/" + this.id + "/variants-names/show")
        .then((result) => {
          const data = result.data.data;
          for (let index in data.variantsName) {
            this.oldVariantTitle.push(data.variantsName[index]);
          }
          for (let index in data.variantsId) {
            this.oldVariantId.push(data.variantsId[index]);
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    getAttrValue() {
      axios
        .get("variants/products/" + this.id + "/sorted-attributes/show")
        .then((result) => {
          const data = result.data.data;
          if (data.length > 0) {
            for (let attr in data) {
              if (data[attr].translation != null) {
                this.attrOptions.push({
                  value: data[attr].id.toString(),
                  text: data[attr].translation.name,
                });
                this.attrSelect.push({
                  value: data[attr].id.toString(),
                  text: data[attr].translation.name,
                  is_variant: data[attr].is_variant,
                });
                this.product.attributes.push({
                  id: "",
                  values: [],
                });
                this.valuesOptions.push([]);
                for (let val in data[attr].values) {
                  this.valuesOptions[attr].push({
                    text: data[attr].values[val].translation.name,
                    value: data[attr].values[val].id.toString(),
                    is_variant: data[attr].is_variant,
                  });
                }
                if (
                  data[attr].values.filter((e) => e.valid === true).length > 0
                ) {
                  for (let val in data[attr].values) {
                    if (data[attr].values[val].valid == true) {
                      this.valuesSelect.push({
                        text: data[attr].values[val].translation.name,
                        value: data[attr].values[val].id.toString(),
                        is_variant: data[attr].is_variant,
                      });
                    }
                  }
                } else {
                  this.valuesSelect.push([]);
                }
              }
              this.combTextArr.push([]);
              this.combValuesArr.push([]);
            }
            this.attr2Options = this.attrOptions;
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchData();
    this.getVariantTitles();
    this.getAttrValue();
    // axios
    //   .get("products/" + this.id + "/translations/show")
    //   .then((result) => {
    //     const data = result.data.data;
    //     this.translations = data;
    //   })
    //   .catch((err) => {
    //     this.errors_back.length = 0;
    //     if (err.response.data.data != null) {
    //       if (this.isString(err.response.data.data)) {
    //         this.errors_back.push({
    //           error: err.response.data.data,
    //         });
    //       } else {
    //         this.errors_back = err.response.data.data;
    //       }
    //       this.showDismissibleAlert = true;
    //     } else {
    //       this.errors_back = [];
    //       this.errors_back.push({
    //         error: "internal server error",
    //       });
    //       this.showDismissibleAlert = true;
    //     }
    //   });

    axios
      .get("products/" + this.id)
      .then((result) => {
        this.itemId = result.data.data.phoneGuide.id;
        this.vendorId = result.data.data.vendor.id;
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
    axios
      .get("products/create")
      .then((result) => {
        const data = result.data.data;
        if (data.conditions.length > 0) {
          for (let ind in data.conditions) {
            this.conditionOptions.push({
              text: data.conditions[ind].name.en,
              value: data.conditions[ind].id.toString(),
            });
          }
        }
        // if (data.attributes.length > 0) {
        //     for (let attr in data.attributes) {
        //         if (data.attributes[attr].translation != null) {
        //             this.attrOptions.push({
        //                 value: data.attributes[attr].id.toString(),
        //                 text: data.attributes[attr].translation.name
        //             })
        //         }
        //     }
        //     this.attr2Options = this.attrOptions
        // }
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
  },
};
</script>
<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.repeater-form {
  transition: 0.35s height;
}
</style>
